import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useDispatch, useQueryParams, useSelector } from 'services/hooks';
import { selectSiteData } from 'selectors';
import { getClasses } from 'ducks/classes/list';
import { selectActiveSiteData } from 'selectors/sites';
import SliderArrow from 'vectors/sliderArrow.svg';

import './swiperStyle.css';
import {
  Container, DayButton, DayText, DateText, ArrowButtonNext, ArrowButtonPrev, ExtendedSliderArrow,
} from './styled';

export const BookingDaySelector:React.FC<BookingDaySelectorProps> = ({ days }) => {
  const dispatch = useDispatch();
  const { setQueryParams, queryParams } = useQueryParams();
  const activeSite = useSelector(selectActiveSiteData);
  const activeDay = queryParams.date?.[0];
  const sites = useSelector(selectSiteData);

  const setActiveDate = (day: Dayjs) => {
    const selectedDay = dayjs(day).format('YYYY-MM-DD');
    const site = activeSite || sites[0]?.city;

    setQueryParams({ ...queryParams, date: [selectedDay] });
    dispatch(getClasses(site, selectedDay));
  };

  const swiperParams = {
    className: 'booking__swiper',
    loopFillGroupWithBlank: false,
    grabCursor: true,
    slidesPerView: 6,
    spaceBetween: 8,
    navigation: {
      navigation: true,
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  };

  return (
    <Container>
      <Swiper
        {...swiperParams}
        modules={[Navigation]}
      >
        {days.map((day, i) => {
          const isActive = dayjs(day).isSame(activeDay, 'day');

          return (
            <SwiperSlide key={i}>
              <DayButton
                onClick={() => setActiveDate(day)}
                isActive={isActive}
              >
                <DayText
                  $uppercase
                  $weight={700}
                  $size={12}
                  isActive={isActive}
                >
                  {dayjs(day).isSame(new Date(), 'day') ? 'Today' : dayjs(day).format('ddd')}
                </DayText>
                <DateText
                  $uppercase
                  $weight={700}
                  $size={12}
                  isActive={isActive}
                >
                  {dayjs(day).format('DD MMM')}
                </DateText>
              </DayButton>
            </SwiperSlide>
          );
        })}
        <ArrowButtonNext className="swiper-button-next">
          <SliderArrow />
        </ArrowButtonNext>
        <ArrowButtonPrev className="swiper-button-prev">
          <ExtendedSliderArrow />
        </ArrowButtonPrev>
      </Swiper>
    </Container>
  );
};

type BookingDaySelectorProps = {
  days: Dayjs[];
};
