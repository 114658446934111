import styled, { css } from 'styled-components';
import SliderArrow from 'vectors/sliderArrow.svg';
import { media } from 'styles/utils';

import { Text } from 'common/typography';

export const Container = styled.div`
  ${media.tablet`
    padding: 0 24px;
    display: flex;
  `}

  ${media.desktop`
    padding: 0px;
  `}

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const DayButton = styled.div<DayButtonProps>`
  height: 45px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 2px;

  ${({ isActive }) => isActive && css`
    background-color: ${({ theme }) => theme.colors.secondary};
    border: 1px solid ${({ theme }) => theme.colors.white};
  `}

  ${media.desktop`
    height: 48px;
    padding: 4px;
  `}
`;

export const DayText = styled(Text) <DayButtonProps>`
  color: ${({ theme }) => theme.colors.black};
`;

export const DateText = styled(Text) <DayButtonProps>`
  color: ${({ theme }) => theme.colors.black};
`;

type DayButtonProps = {
  isActive: boolean;
};

export const ArrowButtonNext = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  height: 100%;
  top: 38%;
  right: 0;
  padding: 0 8px;

  &::after {
    content: '';
  }
`;

export const ArrowButtonPrev = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 4px;
  height: 100%;
  top: 38%;
  left: 0;
  padding: 0 8px;

  &::after {
    content: '';
  }
`;

export const ExtendedSliderArrow = styled(SliderArrow)`
  transform: rotate(180deg);
`;
