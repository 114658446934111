import styled from 'styled-components';

import { media } from 'styles/utils';
import FilterSvg from 'vectors/filter.svg';

export const DaySelectorWrapper = styled.div`
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  margin: 0 auto;

  ${media.desktop`
    position: relative;
    width: 100%;
    top: 50px;
    left: 0px;
    grid-column: 6/13;
  `}
`;

export const SiteSelectorWrapper = styled.div`
  flex: 1;

  ${media.desktop`
    position: relative;
    top: 56px;
    padding: 0;
    grid-column: 1/5;
    width: 85%;
  `}
`;

export const FilterButtonIcon = styled(FilterSvg)`
  width: 24px;
  height: 24px;
`;

export const FilterButton = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: none;

  ${media.desktop`
    position: relative;
    top: 55px;
    grid-column: 5;
    right: 50px;
  `}
`;

export const FilterCount = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 18px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gray.medium};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 90px;
  right: 22px;

  ${media.desktop`
    align-items: center;
    justify-content: center;
    top: -2px;
    right: -1px;
  `}
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 0;
  max-width: 1440px;
  gap: 8px;

  ${media.desktop`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: 1440px;
    margin: 0 auto;
    padding: 24px 0px;
    gap: 0;
  `}
`;

export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  min-height: 184px;
  padding: 0 24px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px 0 12px;

  ${media.desktop`
    padding: 36px 0 0;
  `}
`;
