import styled, { css } from 'styled-components';

export const FilterSectionContainer = styled.div`
  padding-bottom: 36px;
`;

export const FilterSectionHeader = styled.div`
  margin-bottom: 8px;
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 6px;
`;

export const FilterButton = styled.button<FilterButtonProps>`
  border: 1px solid ${({ theme }) => theme.colors.gray.light};
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.primary};
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  border-radius: 14px;
  padding: 7px 16px;
  transition: all .1s ease-in;
  cursor: pointer;
  
  ${({ isActive }) => isActive && css`
    border-color: transparent;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.gray.medium};
  `}
`;

type FilterButtonProps = {
  isActive: boolean;
};
