import * as i from 'types';
import styled, { css } from 'styled-components';

import { Text } from 'common/typography';
import { getColor } from 'styles/utils';

export const BookingLabelWrapper = styled.div<LabelWrapperProps>`
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};
  width: fit-content;
  border-radius: 0px 0px 4px 4px;
  margin: 0 24px 0 0;
  padding: 0 8px;
`;

export const ExtendedText = styled(Text)<ExtendedTextProps>`
  line-height: 20px;
  size: 12px;

  ${({ textColor }) => textColor && css`
    color: ${getColor(textColor)};
  `}
`;

type LabelWrapperProps = {
  backgroundColor: i.LabelColors;
};

type ExtendedTextProps = {
  textColor?: i.TextColors;
};
