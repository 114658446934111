import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useDevice, useSelector, useQueryParams } from 'services/hooks';
import { selectFilterOptions } from 'selectors/classesList';
import { selectSiteStudios } from 'selectors/sites';
import { Modal } from 'common/layout';
import { Text } from 'common/typography';
import { Button } from 'common/interaction';

import { BookingFilterSection } from '..';
import { daypartOptions } from './daypartOptions';
import {
  FilterContainer, Header, CloseIcon, IconContainer, Footer, ExtendedButton,
  HorizontalLine,
} from './styled';

export const FilterModal: React.FC<FilterModalProps> = ({ isModalOpen, handleCloseModal }) => {
  const { t } = useTranslation();
  const filterOptions = useSelector(selectFilterOptions);
  const filterOptionsStudios = useSelector(selectSiteStudios) || [];
  const { isDesktop } = useDevice();
  const { setQueryParams, queryParams } = useQueryParams();
  const [localFilters, setLocalFilters] = React.useState<i.ActiveClassesFilterRecord>(queryParams);

  React.useEffect(() => {
    setLocalFilters(queryParams);
  }, [isModalOpen]);

  const selectFilterOption = (type: keyof i.ActiveClassesFilterRecord, value: string) => {
    const array = localFilters?.[type];

    setLocalFilters({
      ...queryParams,
      ...localFilters,
      [type]: array?.includes(value)
        ? array?.filter((filter) => filter !== value)
        : [...(array || []), value],
    });
  };

  const applyFilters = () => {
    setQueryParams(localFilters);
    handleCloseModal();
  };

  const resetFilters = () => {
    setQueryParams({ date: localFilters.date || [] });
    handleCloseModal();
  };

  const mobileVariant = {
    open: {
      opacity: 1,
      x: 0,
    },
    closed: {
      opacity: 0,
      x: '-100%',
    },
  };

  const desktopVariant = {
    open: {
      opacity: 1,
      x: '-75%',
    },
    closed: {
      opacity: 0,
      x: '-100%',
    },
  };

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <FilterContainer
        animate={isModalOpen ? 'open' : 'closed'}
        initial="open"
        variants={animationVariant}
        key="filterContainer"
      >
        <IconContainer onClick={handleCloseModal}>
          <CloseIcon />
        </IconContainer>
        <Header>
          <Text
            $size={30}
            $weight={400}
            $lineHeight="32"
          >
            {t('filters')}
          </Text>
        </Header>
        <HorizontalLine />
        <>
          {filterOptionsStudios && (
            <BookingFilterSection
              title={t('studios')}
              filterOptions={filterOptionsStudios}
              type="studios"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.studios}
            />
          )}
          <BookingFilterSection
            title={t('time')}
            filterOptions={daypartOptions}
            type="dayparts"
            selectFilterOption={selectFilterOption}
            activeOptions={localFilters.dayparts}
          />
          {filterOptions?.classTypes && (
            <BookingFilterSection
              title={t('classtype')}
              filterOptions={filterOptions?.classTypes}
              type="classTypes"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.classTypes}
            />
          )}
          {filterOptions?.instructors && (
            <BookingFilterSection
              title={t('trainers')}
              filterOptions={filterOptions?.instructors}
              type="instructors"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.instructors}
            />
          )}
        </>
        <Footer>
          <Button onClick={resetFilters}>
            {t('clear')}
          </Button>
          <ExtendedButton
            variant="CTA"
            size="fullWidth"
            onClick={applyFilters}
          >
            {t('apply')}
          </ExtendedButton>
        </Footer>
      </FilterContainer>
    </Modal>
  );
};

type FilterModalProps = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
};
