import * as React from 'react';
import { Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import {
  selectTotalRemainingSeries,
  userClassesData,
  starterClassesDataSelector,
  selectActiveContract,
} from 'selectors';
import { getMeSeries, getMeContracts } from 'ducks/meSeries';
import { getUserClasses } from 'ducks/me';
import { useAuthenticationUser, useDispatch, useSelector } from 'services/hooks';
import { Text } from 'common/typography';
import { Button } from 'common/interaction';
import AddIcon from 'vectors/add.svg';

import { GreetingBlockCta, FirstTimeClassBooking } from '..';
import { GreetingBlockWrapper, Column, Span, HorizontalLine } from './styled';

export const GreetingBlock:React.FC<GreetingBlockProps> = ({ firstName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const remainingSeries = useSelector(selectTotalRemainingSeries);
  const starterClasses = useSelector(starterClassesDataSelector);
  const userBookedClasses = useSelector(userClassesData) || [];
  const activeContract = useSelector(selectActiveContract);
  const seriesName = activeContract?.seriesName;
  const { authenticated, determined } = useAuthenticationUser();

  React.useEffect(() => {
    if (authenticated) {
      dispatch(getMeContracts());
      dispatch(getUserClasses());
      dispatch(getMeSeries());
    }
  }, [determined]);

  if (!determined) return null;

  return (
    <GreetingBlockWrapper>
      {authenticated ? (
        <Column>
          {!activeContract ? (
            <Text
              $size={30}
              $lineHeight="40"
            >
              {t('hey, {{ firstName }}', { firstName })} <br />
              <Trans i18nKey="booking.remainingSeries" values={{ remainingSeries }}>
                You have <Span>{remainingSeries}</Span> class(es).
              </Trans>
            </Text>
          ) : (
            <>
              <Text
                $size={30}
                $lineHeight="40"
              >
                {t('hey, {{ firstName }}', { firstName })} <br />
                {t('welcome back')}
              </Text>
              <Text
                $size={14}
                $lineHeight="20"
              >
                {t('you have unlimited class credits thanks to your {{ seriesName }} membership', { seriesName })}.
              </Text>
            </>
          )}
          {remainingSeries === 0 && !activeContract && (
            <Column>
              <Text
                $size={14}
              >
                {t('please add workouts to continue')}
              </Text>
              <Button
                size="fullWidth"
                variant="CTA"
                icon={<AddIcon fill="white" />}
                onClick={() => navigate('/pricing')}
              >
                {t('buy workouts')}
              </Button>
            </Column>
          )}
        </Column>
      ) : (
        <Text
          $size={30}
          $lineHeight="42"
        >
          {t('you must be logged in to book a class')}
        </Text>
      )}
      <HorizontalLine />
      <Column>
        <GreetingBlockCta />
        {starterClasses && userBookedClasses?.length === 0 && (
          <FirstTimeClassBooking item={starterClasses} />
        )}
      </Column>
    </GreetingBlockWrapper>
  );
};

type GreetingBlockProps = {
  firstName?: string;
};
