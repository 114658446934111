import * as React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useSelector, useDevice, useQueryParams } from 'services/hooks';
import { Text } from 'common/typography';
import { selectActiveSiteData } from 'selectors';
import { SiteSelector } from 'common/interaction';
import { Logo } from 'common/general';

import { BookingDaySelector, MobileBookingDayselector, FilterModal } from '..';
import {
  HeaderContainer, LogoWrapper, DaySelectorWrapper, FilterContainer, SiteSelectorWrapper,
  FilterButtonIcon, FilterButton, FilterCount,
} from './styled';

export const BookingHeader = () => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const { queryParams, setQueryParams } = useQueryParams();
  const activeSite = useSelector(selectActiveSiteData);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const amountOfDays = 28;
  const filters = { ...queryParams };

  const days = [...Array(amountOfDays)].map((_, i) => {
    const today = new Date();
    return dayjs(today).add(i, 'day');
  });

  if (filters.date) delete filters.date;

  return (
    <>
      <HeaderContainer>
        <LogoWrapper>
          <Logo variant="black" />
        </LogoWrapper>
        <FilterContainer>
          {activeSite && (
            <SiteSelectorWrapper>
              <SiteSelector
                handleSelect={() => setQueryParams({ date: queryParams.date })}
                label={t('workouts of')}
              />
            </SiteSelectorWrapper>
          )}
          <FilterButton onClick={() => setIsModalOpen(true)}>
            {Object.keys(filters).length > 0 && (
              <FilterCount>
                <Text $weight={700}>{Object.keys(filters).length}</Text>
              </FilterCount>
            )}
            <FilterButtonIcon />
          </FilterButton>
          <DaySelectorWrapper>
            {!isMobile ? (
              <BookingDaySelector days={days} />
            ) : (
              <MobileBookingDayselector days={days} />
            )}
          </DaySelectorWrapper>
        </FilterContainer>
      </HeaderContainer>
      <FilterModal isModalOpen={isModalOpen} handleCloseModal={() => setIsModalOpen(false)} />
    </>
  );
};
